// media-park
import mediaparkTopLogo from '../assets/img/mediaparkLogo.png';
import mediaparkBG from '../assets/img/slider/slider-1.jpg';
import mediaparkBottomLogo from '../assets/img/logo1.png';

// idea
import ideaTopLogo from '../assets/img/logo-idea-white.png'
import ideaLogo from '../assets/img/idea-bg.jpg'
import ideaLogoBottom from '../assets/img/idea-logo.png'

// t-store
import tStoreBottomLogo from '../assets/img/t-store-bottom-logo.png';
import tStoreBg from '../assets/img/t-store-bg-2.jpg';

// texno-mart
import texnomartTopLogo from '../assets/icons/headingLogo.svg';
import texnomartBG from '../assets/img/slider/slider-2.webp';
import texnomartBottomLogo from '../assets/img/logo2.png';

// arena-markaz
import arenaMarkazLogo from '../assets/img/arena-markaz-logo.svg'
import arenaMarkazBg from '../assets/img/arena-markaz-bg.jpg'

// elamakon
import elmakonTopLogo from '../assets/img/elmakon-logo-thin.png'
import elmakonLogo from '../assets/img/elmakon-logo.png'
import elmakonBg from '../assets/img/elmakon-bg.jpg'

import { scssVars } from "@/utils/constants";

const { main, white } = scssVars

export default [
  {
    logo: mediaparkBottomLogo,
    img: mediaparkBG,
    headinglogo: mediaparkTopLogo,
    href: 'https://mediapark.uz',
    'title-uz':
      'MEDIAPARK.UZ - Toshkent shahri va viloyatlar aro ko’plab do’konlariga ega maishiy texnika va elektronika savdosiga ixtisoslashgan do’konlar tarmog’i. Mahsulotlarning narx va sifat nisbati bo`yicha eng ilg’or takliflar aynan shu do’konlarda. ',
    'title-ru':
      'MEDIAPARK.UZ — интернет магазин, специализирующийся на продаже бытовой техники и электроники в Ташкенте и всей Республике Узбекистан. Мы предлагаем максимальный выбор продукции с оптимальным соотношением цены и качества.',
    btn: {
      color: white,
      bg: main,
    },
  },
  {
    logo: ideaLogoBottom,
    img: ideaLogo,
    headinglogo: ideaTopLogo,
    class: 'macbro',
    href: 'https://idea.uz/',
    'title-uz':
      'IDEA – bu jahon brendlarining sertifikatlangan maishiy texnika va elektronika mahsulotlarining keng assortimentiga ega, zavod kafolati va keyingi xizmat ko‘rsatish, shuningdek, mijozga to‘g‘ri tanlov qilishda yordam berishga doimo tayyor bo‘lgan malakali xodimlarga ega do‘konlar tarmog‘idir.',
    'title-ru':
      'IDEA — это сеть магазинов c широким ассортиментом сертифицированной бытовой техники и электроники от мировых брендов, с заводской гарантией и последующим сервисом, а также квалифицированным персоналом, который всегда готов помочь сделать клиенту правильный выбор.',
    btn: {
      color: main,
      bg: white,
    },
  },
  {
    logo: texnomartBottomLogo,
    img: texnomartBG,
    headinglogo: texnomartTopLogo,
    href: 'https://texnomart.uz/',
    'title-uz':
      "Texnomart - bozor narxlarida savdo qiluvchi maishiy texnika va elektronika do'konlari tarmog'i. Tarmoqning istalgan do’konidan yoki veb-sayt orqali o’zingizga qulay bo’lgan usulda bepul etkazib berish xizmati asosida xarid qilishingiz mumkin.  ",
    'title-ru':
      'Texnomart - это сеть магазинов бытовой техники с ценами как на базаре. Приобрести товары можно любым удобным для вас способом в магазинах сети и прямо на сайте с бесплатной доставкой до дома.',
    btn: {
      color: white,
      bg: main,
    },
  },
  {
    logo: tStoreBottomLogo,
    img: tStoreBg,
    headinglogo: tStoreBottomLogo,
    href: 'https://www.instagram.com/tstore_uz/',
    'title-uz':
      "T-Store - bu maishiy texnika, mobil qurilmalar, kompyuterlar va ularning aksessuarlarini sotishga ixtisoslashgan do'konlardir. Do'konlar tarmog'i sizni tovarlar sifati, xizmat ko'rsatish darajasi, shuningdek, hamyonbop narxlar bilan hayratda qoldiradi.",
    'title-ru':
      'T-Store – магазины, специализирующиеся на  продаже бытовой техники, мобильных устройств,  компьютеров и их комплектующих. Сеть магазинов приятно вас удивит качеством товаров, сервиса, а также доступными ценами.',
    btn: {
      color: main,
      bg: white,
    },
  },
  {
    logo: arenaMarkazLogo,
    img: arenaMarkazBg,
    headinglogo: arenaMarkazLogo,
    href: 'https://arenamarkaz.uz/',
    'title-uz':
      "Arena Markaz – 2018 yilda ochilgan gadjetlar do‘konlari tarmog‘i bo‘lib, u yerda siz smartfonlar, mobil aksessuarlar, noutbuklar, planshetlar, elektrosamokatlar va boshqa ko‘plab mashhur gadjetlarni xarid qilishingiz mumkin. Arena Markaz do‘konlari faqat sertifikatlangan mahsulotlarni taqdim etadi.",
    'title-ru':
      'Arena Markaz — это сеть магазинов гаджетов, открывшаяся в 2018 году, где вы можете купить смартфоны, мобильные аксессуары, ноутбуки, планшеты, электросамокаты и многие другие популярные гаджеты. В магазинах Arena Markaz предоставляется только сертифицированная продукция.',
    btn: {
      color: white,
      bg: main,
    },
  },
  {
    logo: elmakonLogo,
    img: elmakonBg,
    headinglogo: elmakonTopLogo,
    href: 'https://elmakon.uz/',
    'title-uz':
      "ELMAKON – bu har qanday turdagi mobil qurilmalar va iste’mol uskunalari bo‘yicha professional maslahatlar, kreativ va raqobatbardosh aksiyalar taqdim etuvchi, doimiy ravishda eng ommabop mobil qurilmalar va jahon ishlab chiqaruvchilarining so‘nggi yangiliklari, shuningdek, aqlli va qiziqarli uy va dam olish uchun qurilmalar mavjud bo'lgan do‘konlar tarmog‘i.",
    'title-ru':
      'Сеть магазинов ELMAKON - это всегда  профессиональная консультация по любому типу мобильных устройств и потребительской техники, креативные и конкурентоспособные акции, постоянное наличие наиболее популярных мобильных устройств и последних новинок от мировых производителей, а также ассортимент умных и интересных устройств для дома и отдыха.',
    btn: {
      color: white,
      bg: main,
    },
  },
];
