<template>
  <section>
    <div class="container">
      <div class="jscb">

        <h2>{{ localize("step")["title"] }}</h2>

        <router-link :to="{ name: 'how-it-works' }" class="shop_link scrollLink">

          {{ localize("step")["link"] }}

          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.35355 5.64645C9.15829 5.45118 8.84171 5.45118 8.64645 5.64645C8.45118 5.84171 8.45118 6.15829 8.64645 6.35355L9.35355 5.64645ZM14.2929 11.2929L13.9393 11.6464V11.6464L14.2929 11.2929ZM14.2929 12.7071L13.9393 12.3536V12.3536L14.2929 12.7071ZM8.64645 17.6464C8.45118 17.8417 8.45118 18.1583 8.64645 18.3536C8.84171 18.5488 9.15829 18.5488 9.35355 18.3536L8.64645 17.6464ZM8.64645 6.35355L13.9393 11.6464L14.6464 10.9393L9.35355 5.64645L8.64645 6.35355ZM13.9393 12.3536L8.64645 17.6464L9.35355 18.3536L14.6464 13.0607L13.9393 12.3536ZM13.9393 11.6464C14.1346 11.8417 14.1346 12.1583 13.9393 12.3536L14.6464 13.0607C15.2322 12.4749 15.2322 11.5251 14.6464 10.9393L13.9393 11.6464Z"
              fill="#6610F5"
            />
          </svg>
        </router-link>
      </div>

      <div class="row">
        <div
          class="col-ml-4 col-xl-4 col-lg-4 col-md-6 mb-32"
          v-for="(n, i) in step"
          :key="i"
        >
          <card-bus v-bind="n" />
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import shoppingCartIcon from "../../assets/icons/shopping-cart-add-2.svg";
import loyaltyCardIcon from "../../assets/icons/loyalty-card.svg";
import fiveStarIcon from "@/assets/icons/5-star.svg";

import localize from "../../filters/localize.filter";
import cardBus from "../cardBus.vue";

export default {
  components: { cardBus },
  name: "stepSection",
  methods: {
    localize
  },
  data: () => ({
    step: [
      {
        icon: shoppingCartIcon,
        heading: localize("step")["buy"]["title"],
        title: localize("step")["buy"]["text"]
      },
      {
        icon: loyaltyCardIcon,
        heading: localize("step")["renewableLimit"]["title"],
        title: localize("step")["renewableLimit"]["text"]
      },
      {
        icon: fiveStarIcon,
        heading: localize("step")["calculations"]["title"],
        title: localize("step")["calculations"]["text"]
      }
    ]
  })
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/mixins/font.scss";

section {
  .mb-32 {
    margin-bottom: 32px;
  }

  padding-top: calc(104px - 30px);
  padding-bottom: 124px;

  .jscb {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 80px;
  }

  h2 {
    @include font(40px, 700, 40px, $black, false);
  }

  .shop_link {
    font-family: "TT Travels Trl", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 111.63%;
    display: flex;
    align-items: center;
    color: $main;
    text-decoration: none;
  }
}

@media (max-width: 575px) {
  section {
    padding-top: 62px;
    padding-bottom: 24px;
  }
  section h2 {
    font-family: 'TT Travels Trl', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: $black;
    margin-bottom: 8px;
  }
  section .jscb {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  section .mb-32 {
    margin-bottom: 24px;
  }
}

@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.3333333333% !important;
  }
}

@media (min-width: 1200px) {
  .col-ml-4 {
    flex: 0 0 auto;
    width: calc(100% / 12 * 4) !important;
  }
}

@media (min-width: 1400px) {
  .col-xl-4 {
    width: calc(100% / 12 * 4) !important;
  }
}

@media (min-width: 1600px) {
  .col-xxl-3 {
    width: calc(100% / 12 * 3) !important;
  }
}
</style>
