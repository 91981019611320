<template>
  <section style="padding-top: 104px">
    <div class="container">
      <div class="jcsb">
        <h2>{{ title }}</h2>

        <router-link :to="{name: 'partners'}" class="shop_link scrollLink">
          {{ localize("button")["shopsAll"] }}
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.35355 5.64645C9.15829 5.45118 8.84171 5.45118 8.64645 5.64645C8.45118 5.84171 8.45118 6.15829 8.64645 6.35355L9.35355 5.64645ZM14.2929 11.2929L13.9393 11.6464V11.6464L14.2929 11.2929ZM14.2929 12.7071L13.9393 12.3536V12.3536L14.2929 12.7071ZM8.64645 17.6464C8.45118 17.8417 8.45118 18.1583 8.64645 18.3536C8.84171 18.5488 9.15829 18.5488 9.35355 18.3536L8.64645 17.6464ZM8.64645 6.35355L13.9393 11.6464L14.6464 10.9393L9.35355 5.64645L8.64645 6.35355ZM13.9393 12.3536L8.64645 17.6464L9.35355 18.3536L14.6464 13.0607L13.9393 12.3536ZM13.9393 11.6464C14.1346 11.8417 14.1346 12.1583 13.9393 12.3536L14.6464 13.0607C15.2322 12.4749 15.2322 11.5251 14.6464 10.9393L13.9393 11.6464Z"
              fill="#6610F5"
            />
          </svg>
        </router-link>
      </div>

      <div class="swiper-container gallery-top">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            :style="`background-image:url('${n.img}');`"
            v-for="(n, i) in cardItem"
            :key="i"
          >
            <div class="row" style="width: 100%; height: 100%">
              <div class="col-xl-5 col-ml-5 col-lg-5 slide_card">
                <div class="top">
                  <img :src="n.headinglogo" alt="" width="182" />
                  <p :class="n.class">{{ n.title }}</p>
                </div>

                <a
                  :href="n.href"
                  class="p-button"
                  :style="`background:${n.btn.bg};color:${n.btn.color};`"
                >
                  {{ localize("button")["go-to-site"] }}
                </a>
              </div>

              <div class="col-xl-7 col-ml-7 col-lg-7"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="swiper-container gallery-thumbs">
        <div class="swiper-wrapper">
          <div
            :class="['swiper-slide', n.classBG]"
            v-for="(n, i) in cardShop"
            :key="i"
          >
            <img :src="n.logo" alt="" />
          </div>
        </div>
      </div>

      <div class="row sm-block">
        <div class="col-4" v-for="(n, i) in cardShop" :key="i">
          <a :href="n.href" target="_blank" class="card scrollLink">
            <img :src="n.logo" alt="" />
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper from "swiper/swiper-bundle.min.js";
import cardShop from "../../data/carouselShop.js";
import localize from "@/filters/localize.filter";
import "swiper/swiper-bundle.min.css";
import i18n from "../../i18n/i18n";

export default {
  name: "shopSection",
  props: {
    dataJson: { type: Array },
    title: { type: String }
  },
  data: () => ({
    cardShop,
    cardItem: cardShop.map((n) => {
      if (i18n.locale === "ru") {
        n.title = n["title-ru"];
      } else {
        n.title = n["title-uz"];
      }
      return n;
    })
  }),
  methods: { localize },
  mounted() {
    var galleryThumbs = new Swiper(".gallery-thumbs", {
      spaceBetween: 32,
      slidesPerView: 6,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        // when window width is >= 480px
        576: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        // when window width is >= 640px
        768: {
          slidesPerView: 4,
          spaceBetween: 32
        },
        992: {
          slidesPerView: 5,
          spaceBetween: 32
        },
        1200: {
          slidesPerView: 6,
          spaceBetween: 32
        }
      }
    });
    new Swiper(".gallery-top", {
      spaceBetween: 10,
      autoplay: {
        delay: 7000,
        disableOnInteraction: false
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      thumbs: { swiper: galleryThumbs }
    });
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/mixins/font.scss";

section {
  .macbro {
    color: $white !important;
  }

  .macbroLogoBG {
    background-color: $black !important;
  }

  .jcsb {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }

  a.p-button {
    cursor: pointer;
    background: $main;
    border-radius: 8px;
    width: auto;
    padding: 18px 24px;
    font-weight: 600;
    font-size: 18px;
    line-height: 111.63%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    transition: 0.2s;
    border: none;

    &:hover {
      background: $main-light;
    }
  }

  h2 {
    @include font(40px, 700, 40px, $black, false);
  }

  .shop_link {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 111.63%;
    display: flex;
    align-items: center;
    color: $main;
    text-decoration: none;
  }

  .swiper-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background: $white;

    &.gallery-top {
      border: 0.5px solid rgba(0, 0, 0, 0.2);
      box-sizing: border-box;
      border-radius: 14px;
    }
  }

  .swiper-slide {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #f6f6f6;
    display: flex;
    justify-content: center;
    align-items: center;

    .top {
      p {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.01em;
        color: $black;
      }
    }
  }

  .gallery-top {
    height: 400px;
    width: 100%;
    margin-bottom: 22px;
    border-radius: 8px;
  }

  .gallery-thumbs {
    height: 176px;
    box-sizing: border-box;
    padding: 10px 0;
  }

  .gallery-thumbs .swiper-slide {
    width: 25%;
    height: 100%;
    background: $grey;
    border-radius: 14px;
    padding: 12px;
    cursor: pointer;

    img {
      max-width: 100%;
      height: auto;
      mix-blend-mode: multiply;
    }
  }

  .gallery-thumbs .swiper-slide-thumb-active {
    background: rgba(249, 249, 249, 1);
    border: 1px solid #6610F5;
  }

  a.btn_carousel {
    background: $main;
    box-shadow: -12px 12px 30px rgba(102, 16, 245, 0.25);
    border-radius: 8px;
    width: auto;
    padding: 18px 24px;
    font-weight: 600;
    font-size: 18px;
    line-height: 111.63%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    transition: 0.2s;

    &:hover {
      background: $main-light;
    }
  }

  .slide_card {
    padding-left: 72px;
    padding-top: 48px;
    padding-bottom: 48px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    p {
      margin-top: 16px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: $black;
    }
  }
}

.sm-block {
  display: none;
}

.col-4 {
  padding: 8px;
}

.card {
  display: inline-block;
  width: 100%;
  padding: 0px 5px;
  background: $grey;
  border-radius: 12px;

  @media (max-width: $mobile) {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

@media (max-width: 1199px) {
  section {
    .slide_card {
      padding-left: 30px;
    }
  }
}

@media (max-width: 991px) {
  .gallery-top {
    display: none;
  }
}

@media (max-width: 991px) {
  .gallery-top {
    display: none;
  }
}

@media (max-width: 575px) {
  .gallery-thumbs {
    display: none;
  }
  .sm-block {
    display: flex;
  }
  section h2 {
    font-family: 'TT Travels Trl', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: $black;
    margin-bottom: 8px;
  }
  section .jcsb {
    margin-bottom: 16px;
  }
  section {
    padding-top: 40px !important;
  }
}
</style>
