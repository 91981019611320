<template>
  <div class="home">
    <Header
      background="#f6f6f6"
      :heading="localize('banner')['buyer']['title']"
      :title="localize('banner')['buyer']['text']"
      :img="headerBg"
      :btnText="localize('button')['register']"
      :btnTo="'/sign-up'"
      padding-bottom="172px"
      padding-top="120px"
      absoluteImg="absolute-image"
    />

    <section class="halyal container">
      <div class="row halyal__row">
        <div class="col-lg-7 halyal__row__text">
          <p>{{ $t("halyal.title") }}</p>
          <p>{{ $t("halyal.text") }}</p>
        </div>
        <div class="col-lg-5 halyal__row__image">
          <img src="@/assets/img/halyal-certificate.png" alt="halyal-certificate">
        </div>
      </div>
    </section>

    <shopSection :title="localize('carousel')['header']['title']" />

    <stepSection />

    <section v-if="news.length > 0" class="cardNew">
      <div class="container">
        <div class="news_title">
          <h2 v-text="localize('banner')['news']['title']"></h2>

          <router-link :to="{ name: 'news' }" class="shop_link">
            {{ localize("banner")["news"]["newsAll"] }}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.35355 5.64645C9.15829 5.45118 8.84171 5.45118 8.64645 5.64645C8.45118 5.84171 8.45118 6.15829 8.64645 6.35355L9.35355 5.64645ZM14.2929 11.2929L13.9393 11.6464V11.6464L14.2929 11.2929ZM14.2929 12.7071L13.9393 12.3536V12.3536L14.2929 12.7071ZM8.64645 17.6464C8.45118 17.8417 8.45118 18.1583 8.64645 18.3536C8.84171 18.5488 9.15829 18.5488 9.35355 18.3536L8.64645 17.6464ZM8.64645 6.35355L13.9393 11.6464L14.6464 10.9393L9.35355 5.64645L8.64645 6.35355ZM13.9393 12.3536L8.64645 17.6464L9.35355 18.3536L14.6464 13.0607L13.9393 12.3536ZM13.9393 11.6464C14.1346 11.8417 14.1346 12.1583 13.9393 12.3536L14.6464 13.0607C15.2322 12.4749 15.2322 11.5251 14.6464 10.9393L13.9393 11.6464Z"
                fill="#6610F5"
              />
            </svg>
          </router-link>
        </div>
      </div>

      <div class="container">
        <swiper class="swiper" :options="swiperOption">
          <swiper-slide
            class="swiper_slider"
            v-for="newsData in news"
            :key="newsData.id"
          >
            <news-card
              :id="newsData.id"
              :slug="newsData.locale.slug"
              :image="newsData.locale.image"
              :heading="newsData.locale.preview_text"
              :title="newsData.locale.title"
              :date="newsData.date"
            />
          </swiper-slide>

          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </section>
  </div>
</template>

<script>
// Sections
import Header from "../components/Header.vue";
import shopSection from "../components/homeSection/shopSection.vue";
import stepSection from "../components/homeSection/stepSection.vue";

// Swiper
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

// system
import config from "../config";
import localize from "../filters/localize.filter";

// components
import newsCard from "../components/NewsCard.vue";

// import ProductCard from '../components/cards/ProductCard';

// images
import headerBg from "../assets/img/1_result.webp";

// services
import ProductService from "@/services/product.service";

SwiperCore.use([Pagination, Navigation]);
export default {
  name: "home",
  data: () => ({
    headerBg,
    products: [],
    cardsNew: localize("news"),
    news: [],
    language:
      localStorage.getItem("lang") === "ru-RU" || !localStorage.getItem("lang")
        ? "ru"
        : "uz",
    swiperOptionProducts: {
      slidesPerView: 4,
      spaceBetween: 32,
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        200: { slidesPerView: 1.1, spaceBetween: 20, freeMode: false },
        // when window width is >= 480px
        576: {
          slidesPerView: 1,
          spaceBetween: 30,
          freeMode: false
        },
        // when window width is >= 640px
        768: { slidesPerView: 2, spaceBetween: 32, freeMode: false },
        992: { slidesPerView: 3, spaceBetween: 32 },
        1200: { slidesPerView: 4, spaceBetween: 25 }
      },
      pagination: { el: ".swiper-pagination", clickable: true }
    },
    swiperOption: {
      slidesPerView: 4,
      spaceBetween: 32,
      freeMode: true,
      breakpoints: {
        // when window width is >= 320px
        200: { slidesPerView: 1.1, spaceBetween: 20, freeMode: false },
        // when window width is >= 480px
        576: {
          slidesPerView: 1,
          spaceBetween: 30,
          freeMode: false
        },
        // when window width is >= 640pxh
        768: { slidesPerView: 2, spaceBetween: 32, freeMode: false },
        992: { slidesPerView: 3, spaceBetween: 32 },
        1200: { slidesPerView: 3, spaceBetween: 25 }
      },
      pagination: { el: ".swiper-pagination", clickable: true }
    }
  }),
  components: {
    shopSection,
    Header,
    newsCard,
    stepSection,
    Swiper,
    SwiperSlide
    // ProductCard,
  },
  methods: {
    localize,
    async getNews() {
      const { data: news } = await this.$axios.get("news/list", {
        headers: {
          "Content-Language": this.$i18n.locale
        }
      });

      if (news.status === "success") {
        const isLocal = /local/.test(config.API_URL);

        let newsData = [];
        if (isLocal) {
          Object.entries(news.data).forEach(([, news]) => {
            newsData.push(news);
          });
        } else {
          newsData = news.data;
        }

        this.news = newsData.filter(({ is_mobile }) => is_mobile === 0);
      } else {
        news.response.errors.forEach((error) => this.$toastError(error));
      }
    }
  },
  async created() {
    this.products = await ProductService.getAll();
    await this.getNews();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/mixins/font.scss";

.news_title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  @media (max-width: $mobile) {
    &.product {
      display: block;
      justify-content: normal;
    }
  }

  h2 {
    @include font(40px, 700, 40px, $black, false);
  }
}

.halyal {
  margin-top: 100px;

  @media (max-width: $mobile) {
    margin-top: 40px;
  }

  &__row {
    margin-right: 0;
    margin-left: 0;
    background-color: $grey;
    border-radius: 14px;
    overflow: hidden;

    &__text {
      padding: 40px 0 21px 48px;

      p {
        &:first-child {
          @include font(40px, 700, 47px, $black, $ffIsInter: false);
          margin-bottom: 7px;

          @media (max-width: $mobile) {
            @include font(22px, 700, 26px);
          }
        }

        &:last-child {
          @include font(16px, 400, 24px, $muted);

          @media (max-width: $mobile) {
            @include font(13px, 400, 18px, $muted);
          }
        }
      }

      @media (max-width: $mobile) {
        padding: 16px;
      }
    }

    &__image {
      position: relative;

      img {
        width: 260px;
        height: 370px;
        position: absolute;
        top: 0;
        left: 60%;
        filter: drop-shadow(-52.7921px -13.7719px 91.8124px rgba(115, 137, 149, 0.12));
        border-radius: 16px;
        transform: rotate(-15deg) translateX(-60%);

        @media (max-width: $tablet-xl) {
          position: inherit;
          width: 200px;
          height: 280px;
          transform: rotate(-15deg) translateX(-50%);
          top: 15px;
          left: 50%;
        }

        @media (max-width: $mobile) {
          width: 120px;
          height: 170px;
        }
      }
    }
  }

}

.shop_link {
  font-family: 'TT Travels Trl';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 111.63%;
  display: flex;
  align-items: center;
  color: $main;
  text-decoration: none;
}

section.cardNew {
  padding-bottom: 150px;

  .swiper {
    padding-bottom: 50px;
  }
}

@media (max-width: 575px) {
  .news_title h2 {
    font-family: 'TT Travels Trl', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
  }
  section.cardNew {
    padding-bottom: 50px;
  }
}
</style>

<style lang="scss">
.swiper-button-next,
.swiper-button-prev {
  color: $main;

  &::after {
    font-size: 30px;
  }
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  .swiper-pagination-bullet {
    transition: 0.4s;
  }

  .swiper-pagination-bullet-active {
    width: 12px;
    height: 12px;
    background: $main;
  }
}

.promotion-photo {
  size: 417px;
  background: url('../assets/img/home-roads.png') no-repeat 100% 84%/50rem 17rem;
  color: #fff;
  display: block;
}
</style>
